const convertColorToLottieColor = (color) => {
    if (typeof color === 'string' && color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        if (!result) {
            throw new Error('Color can be only hex or rgb array (ex. [10,20,30])');
        }
        return [
            Math.round((parseInt(result[1], 16) / 255) * 1000) / 1000,
            Math.round((parseInt(result[2], 16) / 255) * 1000) / 1000,
            Math.round((parseInt(result[3], 16) / 255) * 1000) / 1000,
        ];
    } else if (typeof color === 'object' && color.length === 3 && color.every((item) => item >= 0 && item <= 255)) {
        return [
            Math.round((color[0] / 255) * 1000) / 1000,
            Math.round((color[1] / 255) * 1000) / 1000,
            Math.round((color[2] / 255) * 1000) / 1000,
        ];
    } else if (!color) {
        return undefined;
    } else {
        throw new Error('Color can be only hex or rgb array (ex. [10,20,30])');
    }
};

const initBodymovin = function() {
    Array.prototype.forEach.call(document.getElementsByClassName('bodymovin'), function(elem) {
        let animation = bodymovin.loadAnimation({
            container: elem,
            path: elem.dataset.src,
            renderer: 'svg',
            loop: true,
            autoplay: false,
        });

        elem.addEventListener('mouseover', function() {
            animation.play();
        });
        elem.addEventListener('mouseout', function () {
            animation.pause();
        });
    });
}

const initPriceSwitcher = function() {
    $(".price-switcher.combo-button .button").on("click", function () {
        $(".price-switcher.combo-button .button").toggleClass("is-active");
        $(".plan-price, .price-per").toggleClass("is-hidden");
    });
}


async function sendMail(formData) {
    const response = await fetch('https://mail-api.flying-lama.com/send', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
    });

    return response.json();
}

function sendContactForm() {
    let form = $('#mail_form');

    let mailData = new FormData(form.get(0));

    if (mailData.get('phone')) {
        return;
    }

    let contactName = mailData.get('name');

    let requestData = {
        'name': contactName,
        'email': mailData.get('email'),
        'subject': 'Nachricht von ' + contactName,
        'message': mailData.get('message'),
        'auth-token': 'xE83zcfeiR7TWpNVYTTphKThiYFYv4',
    };

    sendMail(requestData).then(data => {
        if (data.status == 'success') {
            alert('Nachricht erfolgreich versendet');
        } else {
            alert('Fehler beim Senden: \n\n' + data);
        }
    });

    return false;
}

